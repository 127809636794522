exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-of-use-js": () => import("./../../../src/pages/conditions-of-use.js" /* webpackChunkName: "component---src-pages-conditions-of-use-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-tot-life-tsx": () => import("./../../../src/pages/tot-life.tsx" /* webpackChunkName: "component---src-pages-tot-life-tsx" */),
  "component---src-templates-tot-life-blog-tsx": () => import("./../../../src/templates/TotLifeBlog.tsx" /* webpackChunkName: "component---src-templates-tot-life-blog-tsx" */),
  "component---src-templates-tot-life-topic-tsx": () => import("./../../../src/templates/TotLifeTopic.tsx" /* webpackChunkName: "component---src-templates-tot-life-topic-tsx" */)
}

